/* Import Google Font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

/* Background for the whole admin page */
.shoppyn-admin-bg {
  float: left;
  width: 100%;
  background: #fff;
  height: 100vh;
  overflow-y: auto;
  font-family: "Inter", sans-serif;
}

/* Polaris Navigation styles */
.Polaris-Navigation {
  display: inline-block;
}

/* Content within the Polaris Frame */
.Polaris-Frame__Content {
  display: flex !important;
  flex-wrap: wrap;
}

/* User Menu popup */
.user-popup-menu {
  background-color: white;
  border-radius: 0px 0px 6px 6px;
  padding: 10px;
  display: inline-block;
  border: 1px solid lightgray;
  width: 120px;
  margin-top: 10px;
  box-shadow: 0 1pt 6pt rgb(0 0 0 / 15%);
}

/* Padding for left content */
.pl-4-i {
  padding-left: 1rem !important;
}

/* Active Menu Item Style */
.active-menu > a {
  background-color: #000000 !important;
  color: white !important;
}

/* Main content area */
.main-content {
  display: inline-block;
  width: calc(100% - 210px);
  /* margin-left: 15px; */
  padding-top: 15px;
  box-sizing: border-box;
}

/* Sidebar (Navigation) styles */
.Polaris-Navigation {
  max-width: 200px !important;
  width: 200px !important;
  min-width: 200px !important;
}

/* Spinner (loading) styles */
.Polaris-Spinner {
  display: inline-block;
}

/* Full-screen black background */
.black-bg {
  float: left;
  width: 100%;
  background: #000;
  height: 100vh;
}

/* Loader area */
.logo-loader-parent {
  float: left;
  width: 100%;
  height: 100vh;
  text-align: center;
}

/* Waves container (pending waves) */
.pending-waves-parent {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px 20px;
  background: #371752;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  box-sizing: border-box;
}

/* Make text more readable */
.pending-wave-header {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
}

/* Time text for each wave */
.pending-wave-time {
  float: right;
  color: #ff829b;
}

/* User and wave information */
.pending-wave-parent {
  float: left;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.pending-wave-profile {
  width: 60%;
  float: left;
}

.pending-wave-icon {
  height: 40px;
  width: 40px;
  float: left;
  margin-right: 10px;
}

.pending-wave-name-box {
  float: left;
  width: calc(100% - 50px);
}

.pending-wave-user-name {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 5px;
}

.pending-wave-user-role {
  float: left;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  font-weight: normal;
}

/* Adjust responsive layout */
@media screen and (max-width: 768px) {
  .sidebar-width {
    width: 100%;
    max-width: 100%;
  }

  .main-content {
    width: 100%;
    margin-left: 0;
  }

  .Polaris-Navigation {
    width: 100%;
    max-width: 100%;
  }

  .pending-waves-parent, .past-waves-parent {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .new-payment-parent {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 15px;
    width: auto;
  }

  .b-a-inp {
    width: 100%;
  }
}

/* Full width on very small devices */
@media screen and (max-width: 480px) {
  .logo-loader-parent {
    padding: 20px;
  }

  .new-payment-button {
    width: 100%;
  }

  .pending-wave-icon, .past-wave-icon {
    height: 35px;
    width: 35px;
  }

  .pending-wave-name-box, .past-wave-name-box {
    width: calc(100% - 40px);
  }

  .pending-wave-user-name, .past-wave-user-name {
    font-size: 14px;
  }

  .pending-wave-user-role, .past-wave-user-role {
    font-size: 12px;
  }

  .pending-people-text, .past-people-text {
    font-size: 12px;
  }
}
