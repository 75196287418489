@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");

.login-bg {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  font-family: "Inter", sans-serif;
  overflow-y: auto;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

.login-logo-parent {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.login-logo {
  height: 100px;
  width: 100px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.logo-header-parent {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.logo-header {
  color: #111827;
  margin-bottom: 10px;
  margin-top: 15px;
  text-transform: capitalize;
  font-weight: 700;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.login-body-parent {
  width: 60%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20%;
  margin-right: 20%;
  overflow-y: auto;
  padding-bottom: 60px;
}

/* Input Styles */
.login-input {
  padding: 12px 15px;
  background: #ffffff;
  color: black;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  font-size: 14px;
  box-sizing: border-box;
  border: 1px solid #d1d5db;
  margin-bottom: 10px;
  border-radius: 6px;
  height: 50px;
}

.b-r-6-up {
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0;
}

.b-r-6-down {
  border-radius: 0px 0px 6px 6px;
}

/* Remember Me Section */
.remember-forgot-section {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.remember-me-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remember-me-checkbox {
  height: 14px;
  width: 14px;
  margin-right: 5px;
  margin-top: 4px;
  border: 1px solid #d1d5db;
}

.remember-me-text {
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 3px;
  margin-left: 5px;
}

.login-buttons-parent {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

/* Adjusted login button width */
.login-button {
  width: 50%; /* Changed from 100% to 50% */
  padding: 12px 20px;
  background-color: #700cc2 !important;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  margin-left: 25%; /* Center it like the input fields */
  margin-right: 25%;
}

.login-button:disabled {
  background-color: #ddd;
}

.or-text {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .login-body-parent {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .login-input {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .remember-forgot-section {
    width: 100%;
  }
  .login-buttons-parent {
    width: 100%;
  }
}
